body{
  background-color: #f1f1f1 !important;
}
.aeroplane-icon {
    position: relative;
    /* offset-path: path('M20,20 C20,100 200,0 200,100'); */
  }
  
  .aeroplane-icon-image {
    width: 100%;
    height: 100%;
  }
  
  .speed-meter {
    position: absolute;
    top: -1.2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    width: 3.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;

    
  }

/* custom slider for smoothig */
#custom-slider {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

#custom-slider:focus {
  outline: none;
}

#custom-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  animate: 0.2s;
  background: #ddd;
  border-radius: 5px;
}

#custom-slider::-webkit-slider-thumb {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 17.3px 10px 0;
  border-color: transparent blue transparent transparent;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -15px;
  transform: rotate(90deg);
}

#custom-slider:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

.progress {
  border-radius: 0px !important;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
  background: linear-gradient(to right, #ccc, #000);
  height: 2em;
  width: 100%;
  position: absolute;
  bottom: 15px;
}