.time-picker {
    display: flex;
    align-items: center;
  }
  
  .time-buttons button {
    padding: 5px 10px;
    margin-left: 5px;
  }
.custom-datepicker .rmdp-wrapper {
  /* Add your desired z-index value */
  z-index: 99999999;
}
.rmdp-input {
  word-break: break-all;
  height: 3rem;
  width: max-content;
}
#root > div > header > div > div > div > div.col-md-12.d-flex.justify-content-center.d-flex > form > div > div > div:nth-child(1) > div > div:nth-child(3){
  z-index: 999999 !important;
}
#root > div > header > div > div > div > div.col-md-12.d-flex.justify-content-center.d-flex > form > div > div > div:nth-child(2) > div > div:nth-child(3){
  z-index: 999999 !important;
}
  
  