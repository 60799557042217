
#root > div > header > div > div > div > div.col-md-9.p-5 > div.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom
{
  height: 80vh !important;
}
.home-screen-container{
  height: 100vh;
}

.h-100vh{
  height: 100vh;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.custom-marker {
  position: absolute;
  animation: bounce 1s ease infinite;
}

.icon {
  font-size: 20px;
  text-align: center;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


.display-grid{
  display:grid
}
.circle-arrow-bottom{
  right:5%;
  bottom:5%;
}



